._skin-kofl .main {
    position: relative;
    background: radial-gradient(circle, rgba(32,17,51,1) 0%, rgba(53,36,93,1) 35%, rgba(18,6,27,1) 100%);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 460px;
    height: 56px;
    box-sizing: border-box;
}

._skin-kofl .main::before {
    background: #fa3f00;
    left: -7px;
    right: -7px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

._skin-kofl .main::after {
    height: 28px;
    opacity: .31;
    left: -7px;
    right: -7px;
}

._skin-kofl .time {
    position: absolute;
    width: 144px;
    left: calc((100% - 144px)/2);
    top: 0;
    bottom: -6px;
    background-image: url('./slot.png');
    background-position-y: -0.5px;
    background-size: cover;
    text-align: center;
    padding-top: 9px;
    font-size: 23px;
    z-index: 5;
    color: #160a23;
    font-weight: 700;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

._skin-kofl .team {
    position: absolute;
    width: 110px;
    background-color: #00606c;
    top: 0;
    bottom: 0;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

._skin-kofl .team .name {
    padding: unset;
    top: unset;
    left: unset;
    right: unset;
}

._skin-kofl .team:nth-child(2) {
    left: 48px;
    padding-left: 14px;
    border-bottom-left-radius: 16px;
}

._skin-kofl .team:nth-child(4) {
    right: 48px;
    padding-right: 14px;
    border-bottom-right-radius: 16px;
}

._skin-kofl .team .color {
    width: 9px;
    height: 28px;
    z-index: 6;
}

._skin-kofl .score {
    opacity: 0;
}

._skin-kofl  .main .team:nth-child(4) .color:first-child {
    border-top-right-radius: 0;
}

._skin-kofl .main .team:nth-child(4) .color:nth-child(2) {
    border-bottom-right-radius: 13px;
    top: 28px;
}

._skin-kofl .main .team:nth-child(4) .color {
    left: unset;
    right: 0;
}

._skin-kofl .main .team:nth-child(2) .color:nth-child(2) {
    border-bottom-left-radius: 13px;
}

._skin-kofl .split-score {
    position: absolute;
    opacity: 1;
    visibility: visible;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    width: 48px;
    text-align: center;
}

._skin-kofl .split-score._h {
    left: 0;
}

._skin-kofl .split-score._a {
    right: 0;
}

._skin-kofl .main .team::after {
    content: '';
    width: 90px;
    background: #011001;
    height: 4.4px;
    position: absolute;
    bottom: 0;
}

._skin-kofl .main .team:nth-child(4)::after {
    right: 40px;
    border-top-right-radius: 35px;
}

._skin-kofl .main .team:nth-child(2)::after {
    left: 40px;
    border-top-left-radius: 35px;
}

._skin-kofl .stat, ._skin-kofl .event {
    width: 284px;
    left: 108px;
    top: 76px;
    border-radius: 0;
    border-bottom-right-radius: 60px;
    border-bottom-left-radius: 60px;
    /* background: linear-gradient(0deg, rgba(175,175,180,1) 0%, rgba(98,93,97,1) 20%, rgba(241,243,240,1) 100%); */
    background: linear-gradient(0deg, rgba(175,175,180,1) 0%, rgba(98,93,97,0.8463760504201681) 20%, rgba(241,243,240,1) 100%);
}

._skin-kofl .event {
    top: 22px;
    max-height: 0;
    animation: personEvtFlow 11s ease-in-out 0s 1 normal forwards;
}

._skin-kofl .event .content {
    width: 188px;
}

._skin-kofl .event .photo {
    width: 54px;
    text-align: center;
    overflow: hidden;
    border-radius: 12px;
}

._skin-kofl .event .photo img {
    margin-top: 6px;
    width: 54px;
    border-radius: 12px;
    -webkit-mask-image: none;
}


._skin-kofl .event > div {
    color: #160a23;
}

._skin-kofl .stat.static .label, ._skin-kofl .stat.static .value {
    width: unset;
    color: #111;
    font-weight: 700;
    padding-top: 6px;
    font-size: 17px;
}

._skin-kofl .stat.static {
    justify-content: center;
}

@keyframes personEvtFlow {
  0% {
    opacity: 0;
    transform: translateY(0px);
    max-height: 0;
  }

  25% {
    opacity: 1;
    transform: translateY(0);
    max-height: 250px;
  }

  80% {
    opacity: 1;
    transform: translateY(0);
    max-height: 100px;
  }

  90% {
    opacity: 1;
    transform: translateY(0);
    max-height: 0px;
  }

  100% {
    opacity: 0;
    transform: translateY(-100px);
    max-height: 0px;
  }
}
