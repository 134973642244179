@font-face {
    font-family: 'UniviaPro';
    src: url('./UniviaPro-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Intro';
    src: url('./Intro.otf') format('opentype');
}

body {
    margin: 0;
    font-family: 'UniviaPro';
    background: transparent;
    width: 100%;
    overflow: hidden;
}

.broadcast {
    width: 1280px;
    height: 720px;
    background: transparent;
    /* background: url('./frame.png'); */
    position: relative;
    background-size: cover;
    transform-origin: 0 0;
}

.main {
    width: 340px;
    height: 50px;
    position: fixed;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
}

.main.noTimer {
    width: 260px;
}

.main::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(32,17,51,1) 0%, rgba(53,36,93,1) 35%, rgba(18,6,27,1) 100%);
    border-radius: 4px;
    opacity: .9;
    z-index: -1;
}

.main::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 25px;
    background: #fff;
    opacity: .12;
    content: '';
    z-index: 2;
}

.main .time {
    width: 80px;
    color: #fff;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 22px;
    padding-top: 2px;
}

.main.noTimer .time {
    display: none;
}

.main .score {
    font-size: 36px;
    padding-top: 3px;
    width: 90px;
    text-align: center;
    color: #fff;
    letter-spacing: 3px;
    font-family: 'Intro';
    white-space: nowrap;
}

.main .team {
    width: 85px;
    box-sizing: border-box;
    padding-left: 12px;
    position: relative;
}

.main .team .color {
    position: absolute;
    width: 6px;
    height: 25px;
    top: 0;
    left: 0;
    background: #fff;
}

.main .team .color:nth-child(2) {
    top: unset;
    bottom: 0;
}

.main .team:last-child .color {
    right: 0;
    left: unset;
}

.main .team:last-child .color:first-child {
    border-top-right-radius: 3px;
    top: 0px;
}

.main .team:last-child .color:nth-child(2) {
    border-bottom-right-radius: 3px;
    bottom: 0px;
    top: 25px;
}

.main .team .emb {
    position: relative;
    overflow: hidden;
    height: 50px;
}

.main .team .emb img {
    height: 80px;
    top: -15px;
    position: absolute;
    -webkit-mask-image: url('./mask46.svg');
    -webkit-mask-size: cover;
    transform: rotate(-7deg);
    opacity: .11;
}

.main .team:last-child .emb img {
    transform: rotate(7deg);
}

.main .team .name {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: right;
    font-size: 24px;
    color: #fff;
    padding-top: 12px;
    padding-right: 7px;
}

.main .team:last-child .name {
    text-align: left;
    padding-left: 7px;
    padding-right: unset;
}

.stat, .event {
    width: 340px;
    display: flex;
    align-items: center;
    height: 40px;
    position: absolute;
    top: 80px;
    left: 20px;
    background: radial-gradient(circle, rgba(32,17,51,1) 0%, rgba(53,36,93,1) 35%, rgba(18,6,27,1) 100%);
    border-radius: 4px;
}

.stat {
    animation: elSlideIn 6s ease-in-out 0s 1 normal forwards;
}

.stat.static {
    animation: none;
}

.stat .label {
    box-sizing: border-box;
    width: 165px;
    color: #fff;
    text-align: right;
    padding-right: 12px;
    padding-top: 4px;
    font-size: 19px;
}

.stat .value {
    font-size: 25px;
    color: #fff;
    letter-spacing: 2px;
    padding-top: 3px;
    width: 90px;
    text-align: center;
}

.event {
    /* display: flex; */
    height: 80px;
    position: relative;
    animation: elSlideIn 10s ease-in-out 0s 1 normal forwards;
    overflow: hidden;
}

@keyframes elSlideIn {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }

  15% {
    opacity: 1;
    transform: translateX(0);
  }

  90% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(400px);
  }
}

.stat {
    display: flex;
}

.event .icon {
    width: 44px;
    box-sizing: border-box;
    padding-left: 12px;
}

.event .photo {
    width: 80px;
    text-align: center;
    overflow: hidden;
}

.event .photo img {
    margin-top: 18px;
    width: 80px;
    -webkit-mask-image: url('./mask46.svg');
    -webkit-mask-size: 80px;
    -webkit-mask-position: top center;
}

.event .content {
    box-sizing: border-box;
    width: 216px;
    padding-left: 10px;
    color: #fff;
}

.event .content > div:nth-child(2) {
    white-space: nowrap;
}

.event .content > div:last-child {
    opacity: .7;
    font-size: 13px;
}

.event::after, .event::before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    width: 80px;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    background: rgba(255, 255, 255, .07);
    left: 30px;
}

.event::after {
    left: 55px;
    bottom: 0px;
    top: 0px;
    background: rgba(255, 255, 255, .11);
    animation: stripeLeft 2.2s ease-in-out 0s 1 normal forwards;
}

.event::before {
    top: 0px;
    bottom: 0px;
    animation: stripeRight 2.2s ease-in-out 0s 1 normal forwards;
}

@keyframes stripeLeft {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes stripeRight {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.promo {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.promo img {
    width: 120px;
    border-radius: 4px;
}

.split-score {
    opacity: 0;
    visibility: hidden;
}
