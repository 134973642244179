.arena-broadcast {
    padding-top: 20px;
    box-sizing: border-box;
}

.arena-main {
    flex-direction: column;
    position: relative;
    height: unset;
    width: 200px;
    height: 142px;
    margin: 5px 25px;
    opacity: .73;
}

.arena-main::before {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background: url('./metal.jpg');
    background-size: cover;
    border-radius: 8px;
    z-index: 0;
}

.arena-main_content {
    position: relative;
    z-index: 1;
    border-radius: 6px;
    background-color: #000000;
    background-image: linear-gradient(147deg, #33013b 0%, #571065 74%);
    box-shadow: 0 9px 13px 0 rgb(39 106 158 / 17%), 0 10px 15px 0 rgb(21 21 21 / 14%);
}

.arena-item {
    display: flex;
    height: 29px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #480b59;
    position: relative;
}

.arena-item:last-child {
    border-bottom: none;
}

.arena-item.head {
    height: 24px;
    justify-content: flex-end;
    border-bottom: none;
}

.arena-item.head .label {
    width: 50px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
}

.arena-item .team {
    width: calc(100% - 100px);
}

.arena-item .team span {
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    background: #1e0026;
    padding: 3px 6px 1px;
    max-width: 80%;
    white-space: nowrap;
    margin-left: 10%;
    box-sizing: border-box;
    border-radius: 3px;
}

.arena-item .value {
    width: 50px;
    text-align: center;
    font-weight: 700;
    color: #fff;
    font-size: 18px;
    text-shadow: 1px 1px #030101, -1px -1px #333;
}

.arena-item.head .time {
    background-color: #f94327;
    background-image: linear-gradient(316deg, #f94327 0%, #ff7d14 74%);
    padding: 7px 0 4px;
    border-radius: 4px;
    width: 100px;
    position: absolute;
    top: -16px;
    left: -16px;
    font-weight: 700;
    color: #33013b;
    font-size: 19px;
    box-sizing: border-box;
    text-align: center;
}

.arena-item.head .time::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: rgba(255, 255, 255, .09);
}

.arena-item.active::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .17);
}

.arena-item::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 14.5px;
    background: rgba(255, 255, 255, .04);
}

.arena-item.head::before {
    content: none;
}

.arena-item .colors {
    position: absolute;
    right: -8px;
    top: 0;
    height: 29px;
    width: 9px;
    background: #ccc;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: all .3s linear;
}

.arena-item .colors > div {
    width: 100%;
    height: 14.5px;
}

.arena-item.active .colors {
    opacity: 1;
    visibility: visible;
}

.arena-element {
    position: fixed;
    width: 260px;
    height: 70px;
    border-radius: 8px;
    top: 20px;
    left: calc((100% - 260px)/2);
    background-color: #f94327;
    background-image: linear-gradient(316deg, #f94327 0%, #ff7d14 74%);
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 9px 13px 0 rgb(39 106 158 / 17%), 0 10px 15px 0 rgb(21 21 21 / 14%);
    align-items: center;
    opacity: .81;
    animation: elSlideBottom 6s ease-in-out 0s 1 normal forwards;
}

.arena-element .emblem {
    position: relative;
}

/* .arena-element .emblem::before {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background: url('./metal.jpg');
    background-size: cover;
    border-radius: 6px;
    z-index: 0;
} */

.arena-element .emblem-inner {
    border-radius: 5px;
    width: 84px;
    height: 84px;
    background: #fff;
    /* background-image: linear-gradient(147deg, #33013b 0%, #571065 74%); */
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
}

.arena-element .emblem-inner img {
    height: 96px;
    transform: rotate(4deg);
}

.arena-element .info {
    padding-left: 12px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.arena-element .info > div:first-child {
    font-size: 26px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px #e33715, -1px -1px #f26a3f;
}

.arena-element .info > div:last-child {
    font-size: 20px;
    font-weight: 700;
    color: #33013b;
    max-width: 150px;
    white-space: nowrap;
}

@keyframes elSlideBottom {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }

  15% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-300px);
  }
}
